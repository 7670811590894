@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&display=swap');
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  background-color: #e5e5f7;
  opacity: 1;
  background-image: radial-gradient(#919191 0.65px, #e5e5f7 0.65px);
  background-size: 13px 13px;
  font-family: Poppins;
}
*{
  scroll-behavior: smooth;
}
.grid{
  display: grid;
  grid-template-columns: repeat(5,1fr);
  gap: 20px;
  width: 600px;
  place-items: center;
  margin-inline: auto;
  margin-top: 50px;
}
.socials{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
}
.socials img{
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  cursor: pointer;
  
}
.home{
  padding-top: 100px;
}
.title{
  font-size: 46px;
  font-weight: 600;
  color: #000;
  text-align: center;
  margin-block: 0px;
}
.desc{
  font-size: 15px;
  font-weight: 400;
  color: #000;
  text-align: center;
  margin-block: 0px;
  
  max-width: 90%;
  width: 750px;
  margin-inline: auto;
}

.grid .item{
  position: relative;
  width: 100px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  box-shadow: 2px 3px 15px rgba(0,0,0,0.5);
  transition: all 0.4s ease;
  scroll-margin-top: 45vh;

}
.grid .item .tooltiptext {
  visibility: hidden;
  width: 160px;
  font-size: 12px;
  background-color: #919191;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  bottom: -30px;
  z-index: 2;
}
.grid .item:hover .tooltiptext {
  visibility: visible;
}
.grid .item:hover{
  box-shadow: 2px 3px 20px #5353ee;
  transform: scale(1.1);
  z-index: 2;
  cursor: pointer;
}
.highlight {
  box-shadow: 2px 3px 20px red;
  transform: scale(1.1);
}
.highlight img{
  border: 2px solid red!important;
}
.highlight .img-name{
  color: red;
}
.img-name{
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  margin-inline: auto;
  font-size: 12px;
  background-color: #e5e5f7;
  color: #5353ee;
  text-align: center;
  padding: 5px;
  border-radius: 50%;
  margin-block: 0px;
  width: 20px;
}
.grid img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #5353ee;
}

/* shift second row to the right */
.right50 {
  margin-left: 50px!important;
  margin-right: -50px!important;
}

/* shift third row to the right */
.right100 {
  margin-left: 100px!important;
  margin-right: -100px!important;
}


.search{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  gap: 10px;
}
.search input{
  width: 150px;
  padding: 5px;
  font-size: 16px;
  border-radius: 10px;
  border: 2px solid #0000001a;
  outline: none;
  box-shadow: 1px 3px 9px rgba(0, 0, 0,0.3);
  font-family: Poppins;
}
.search input:focus{
  border: 2px solid #5353ee;
}
.search img{
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  cursor: pointer;
}
.search button{
  border-radius: 50%;
  background: rgb(211, 210, 210);
  border: none;
  outline: none;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 3px 9px rgba(0, 0, 0,0.3);
}
.center{
  text-align: center;
  margin-inline: auto;
  margin-top: 100px;
  font-style: italic;
}
@media(max-width: 700px){
  .grid{
    width: calc(100% - 100px);
    gap: 5px;
  }
  .grid .item{
    width: 50px;
    margin-bottom: 10px;
  }
  .right50{
    margin-left: 20px!important;
    margin-right: -20px!important;
  }
  .right100{
    margin-left: 40px!important;
    margin-right: -40px!important;
  }
  .title{
    font-size: 30px;
    margin-top: 50px;
  }
  .desc{
    font-size: 12px;
    margin-top: 30px;
  }
  .search{
    margin-top: 30px;
  }
  .img-name{
    font-size: 10px;
    padding: 3px;
    width: 16px;
  }
}